import React, {useEffect, useRef} from 'react'
import {gsap, ScrollTrigger} from 'gsap/all'

gsap.registerPlugin(ScrollTrigger)

const ContactInfo = () => {
	const contactInfo = useRef(null)
	const leftCard = useRef(null)
	const rightCard = useRef(null)

	useEffect(contactCards, [])

	function contactCards() {
		const tl = gsap
			.timeline({
				defaults: {
					ease: 'Power4.easeInOut',
					duration: 2,
				},
				scrollTrigger: {
					trigger: contactInfo.current,
					start: '1% 80%',
					end: '+=500',
					toggleActions: 'play none none none',
				},
			})
			.timeScale(1)

		tl.fromTo(
			leftCard.current,
			{
				translateX: '-100%',
			},
			{
				translateX: 0,
			}
		).fromTo(
			rightCard.current,
			{
				translateX: '100%',
			},
			{
				translateX: 0,
			},
			'<.1'
		)
	}

	return (
		<section className="contact__info dark-section" ref={contactInfo}>
			<div className="contact__info--left" ref={leftCard}>
				<video
					autoPlay
					loop={true}
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-mob"
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/777285375/rendition/1080p/file.mp4?loc=external&signature=51d82d34bc1327d142551ae85d8efcd5ccee27e6a88e709d65333b3ebbfa3d22'} type={'video/mp4'}/>
				</video>
				<video
					autoPlay
					loop={true}
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-desk"
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/777285353/rendition/1080p/file.mp4?loc=external&signature=ce4113c93e113e2b4f2ded797c079161ac79e537b632bbe84b7c0146e17f9914'} type={'video/mp4'}/>
				</video>
				<div>
					<h3>San Diego</h3>
					<a href="tel:858 333 5992">858 333 5992</a>
					<a href="mailto:welcome@thoagency.com">welcome@thoagency.com</a>
				</div>
			</div>
			<div className="contact__info--right" ref={rightCard}>
				<video
					autoPlay
					loop={true}
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-mob"
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/777285328/rendition/1080p/file.mp4?loc=external&signature=0d996d393c362fd51bd9bddf90a32d983160644f8a63c21e3eafec89f6fa9ed4'} type={'video/mp4'}/>
				</video>
				<video
					autoPlay
					loop={true}
					playsInline
					width="100%"
					height="100%"
					muted
					className="show-desk"
				>
					<source src={'https://player.vimeo.com/progressive_redirect/playback/777285314/rendition/1080p/file.mp4?loc=external&signature=191dafb7c59c21f9e1636075d6407a2df1025f51934fed7a6c1d01bdbe50d403'} type={'video/mp4'}/>
				</video>
				<div>
					<h3>Dallas</h3>
					<a href="tel:858 333 5992">858 333 5992</a>
					<a href="mailto:welcome@thoagency.com">welcome@thoagency.com</a>
				</div>
			</div>
		</section>
	)
}

export default ContactInfo
