import React, {useEffect} from 'react'
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';
import Contact from '../components/pages/contact';

const ContactPage = () => {
    useEffect(() => {
        document.body.style.height = ``
    }, []);
    return (
        <>
            <Seo
                title="Contact"
                description="Get in touch with us"
            />
            <BaseLayout>
                <Contact/>
            </BaseLayout>
        </>
    )
}
export default ContactPage;
