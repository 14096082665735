import React from 'react'

import ContactInfo from './ContactInfo'
import Layout from '../../../layouts/Layout';
import Image from '../../elements/Image'

const Contact = () => {
    return (
        <Layout pageClass="contact">
            <section className="contact__hero">
                <Image src="/images/StayAWhile.jpg" />
            </section>
            <ContactInfo/>
        </Layout>
    )
}

export default Contact
